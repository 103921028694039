
  import { computed, defineComponent, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import Multiselect from '@vueform/multiselect';

  interface FactorOptions {
    id: number;
    slug: string;
    title: string;
  }

  export default defineComponent({
    name: 'factor-values-creating',
    props: {
      factorData: { type: Object, required: false },
    },
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    emit: ['new-value-created'],
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const newFactorValueModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();
      const selectedFactor = ref<number>(0);
      const selectedArea = computed(() => store.getters.estimatorSelectedArea);
      const factorTitle = ref<string>('');

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      watch(
        () => props.factorData,
        (data) => {
          if (data) {
            selectedFactor.value = data.factorId;
            factorTitle.value = data.factorTitle;
          }

          if (selectedFactor.value !== 0) {
            getOptions();
          }
        }
      );

      const validationSchema = Yup.object().shape({
        value: Yup.string()
          .required(() => translate('FACTOR_OPTION_IVE_IS_REQUIRED_FIELD'))
          .label('Value'),
      });

      const submit = async (values, { resetForm }) => {
        if (submitButtonRef.value) {
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = true;
          submitButtonRef.value.setAttribute('data-kt-indicator', 'on');
        }
        values.factorOptionId = selectedOption.value;
        values.factorId = selectedFactor.value;
        values.value = Number(values.value);
        const payload = {
          areaId: selectedArea.value.id,
          factors: [values],
        };

        await store.dispatch(Actions.CREATE_IVE_VALUE, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_IVE_VALUE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emit('new-value-created');
            resetForm();
            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
            hideModal(newFactorValueModalRef.value);
          });
        }
      };

      const factorOptions = ref<Array<FactorOptions>>([]);
      const selectedOption = ref<number | null>(null);
      const getOptions = async () => {
        const { data } = await store.dispatch(
          Actions.GET_IVE_FACTOR_ESTIMATION_OPTIONS,
          { areaId: selectedArea.value.id, factorId: selectedFactor.value }
        );

        factorOptions.value = data.data;
        selectedOption.value = null;
        if (factorOptions.value.length) {
          selectedOption.value = factorOptions.value[0].id;
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        factorOptions,
        selectedOption,
        translate,
        validationSchema,
        submit,
        submitButtonRef,
        newFactorValueModalRef,
        selectedArea,
        factorTitle,
      };
    },
  });
