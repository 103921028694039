
  import { computed, defineComponent, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n/index';
  import { FactorOptionObject } from '@/store/modules/IVE/FactorOptionsModule';

  export default defineComponent({
    name: 'factor-values-editing',
    props: {
      editData: { type: Object, required: false },
    },
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    emit: ['new-value-created'],
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const editFactorValueModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();
      const selectedArea = computed(() => store.getters.estimatorSelectedArea);
      const factorTitle = ref<string>('');
      const optionsValues = ref<Array<FactorOptionObject>>([]);
      let dynamicSchema = ref({});

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      watch(
        () => props.editData,
        (data) => {
          optionsValues.value = data?.value.options;

          dynamicSchema.value = {};
          optionsValues.value.forEach((v) => {
            dynamicSchema.value[`field_${v.id}`] = Yup.string()
              .required(() => translate('FACTOR_OPTION_IVE_IS_REQUIRED_FIELD'))
              .label(v.title);
          });
          if (data) {
            factorTitle.value = data.factorTitle;
          }
        }
      );

      const validationSchema = computed(() => {
        return Yup.object(dynamicSchema.value);
      });

      const submit = async (values) => {
        if (submitButtonRef.value) {
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = true;
          submitButtonRef.value.setAttribute('data-kt-indicator', 'on');
        }

        const factors = Object.keys(values).map((v) => ({
          id: Number(v.replace('field_', '')),
          value: Number(values[v]),
        }));

        await store.dispatch(Actions.UPDATE_IVE_VALUE, {
          id: '',
          data: { factors },
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_IVE_VALUE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emit('new-value-created');
            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
            hideModal(editFactorValueModalRef.value);
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        optionsValues,
        translate,
        validationSchema,
        submit,
        submitButtonRef,
        editFactorValueModalRef,
        selectedArea,
        factorTitle,
      };
    },
  });
